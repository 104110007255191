import React from "react";
import Helmet from "react-helmet";
import Layout from "../components/layout";
import SEO from "../components/seo";

export default ({ data }) => {
  return (
    <Layout>
      <Helmet 
        meta={[
          { name: 'description', content: 'Clearinghouse Navigator About Us' },
          { name: 'keywords', content: 'Clearinghouse Navigator,  About Us' },
        ]}
      />  
      <SEO title="About Clearinghouse Navigator" />
      <main role="main">
          <div className="row">
              <div className="col-md-12">
              <h1 className="text-center mb-5"  dangerouslySetInnerHTML={{ __html: data.nodePage.title }} ></h1>
              </div>
          </div>
      </main>
        
      <div className="text-left" dangerouslySetInnerHTML={{ __html: data.nodePage.body.value }} />
          
    </Layout>
  )
}

export const query = graphql` 
  query {
    nodePage(title: {eq: "About Us"}) {
      id
      title
      body {
        value
      }
    } 
  } `

